/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useState, useEffect } from "react";

import HighchartsReact from "highcharts-react-official";
import Highcharts, { chart, dateFormat } from "highcharts";

import moment from "moment-timezone";
import socket from "../utils/SocketHelper";
import { useParams, useHistory } from "react-router-dom";
import { parse } from "query-string";

function LiveGraphScreen() {
  let params = useParams<{ ids: string; usernames: string }>();
  let history = useHistory();

  const ids = params.ids.split(",");
  const usernames = params.usernames.split(",");

  let [currentHeartrate, setCurrentHeartRate] = useState(0);
  let [maxHeartrate, setMaxHeartRate] = useState(0);
  let [minHeartRate, setMinHeartRate] = useState(999);

  let queryObject = parse(window.location.search) as {
    showGreenScreen?: string;
    accessToken?: string;
  };

  let [chartOptions, setChartOptions] = useState<Highcharts.Options>({
    title: {
      text: "",
    },
    chart: {
      animation: false,
      height: 300,
      width: 800,
    },
    xAxis: {
      plotLines: [...Array(10)].map((_, index) => ({
        color: "#000000AA", // Red
        dashStyle: "Dash",
        width: 1,
        value: index * 10, // Position, you'll have to translate this to the values on your x axis
      })),
      categories: [...Array(100)].map((x) => moment().format("hh:mm:ss")),
      tickInterval: 10,
    },
    series: [
      {
        type: "bar",
        data: [...Array(100)].map((x) => 0),
        color: "red",
        name: "heartrate",
      },
    ],
    plotOptions: {
      series: {
        animation: false,
        marker: {
          enabled: false,
        },
      },
      line: {
        animation: false,
        marker: {
          enabled: false,
        },
      },
    },
  });

  useEffect(() => {
    socket.emit("selectedUserId", {
      userId: ids[0],
      accessToken: queryObject.accessToken,
    });

    socket.on("heartrate", (wsData: { heartrate: number; userId: number }) => {
      const { heartrate } = wsData;
      let data = (chartOptions.series as any)[0].data;
      data.push(heartrate);

      setCurrentHeartRate(heartrate);
      setMaxHeartRate((max) => {
        if (heartrate > max) return heartrate;
        else return max;
      });
      setMinHeartRate((min) => {
        if (heartrate < min) return heartrate;
        else return min;
      });

      let categories = (chartOptions.xAxis as any).categories;

      categories.push(moment().format("hh:mm:ss"));

      if (data.length >= 100) {
        data = data.slice(1, 100);
        categories = categories.slice(1, 100);
      }

      setChartOptions({
        xAxis: {
          categories,
        },
        series: [
          {
            type: "area",
            data,
            color: "#C62121",
          },
        ],
      });
    });

    return () => {
      socket.off("heartrate");
    };
  }, []);

  return (
    <div
      className="App"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <span
        css={css`
          font-size: 34px;
          font-weight: bold;
        `}
      >
        {usernames ? usernames[0] : ""}
      </span>

      <span
        css={css`
          text-decoration: underline;
          margin-bottom: 150px;
          cursor: pointer;
        `}
        onClick={() => {
          history.push("/");
        }}
      >
        go back
      </span>

      <div
        css={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <span style={{ marginBottom: 10 }}>
            current: {currentHeartrate}bpm
          </span>
          <span style={{ marginBottom: 10 }}>max: {maxHeartrate}bpm</span>
          <span>min: {minHeartRate}bpm</span>
        </div>
      </div>
    </div>
  );
}

export default LiveGraphScreen;

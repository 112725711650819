/** @jsx jsx */
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import UserListScreen from "./Screens/UserListScreen";
import LiveGraphScreen from "./Screens/LiveGraphScreen";
import { hot } from "react-hot-loader/root";
import StaticBarScreen from "./Screens/StaticBarScreen";
import LiveTableScreen from "./Screens/LiveTableScreen";
import LiveTemperatureTableScreen from "./Screens/LiveTemperatureTableScreen";
import LiveSimpleRateScreen from "./Screens/LiveSimpleRateScreen";
import { Global, css, jsx } from "@emotion/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PlaybackSimpleRateScreen from "./Screens/PlaybackSimpleRateScreen";
import LoginScreen from "./Screens/LoginScreen";
import SingleLiveVis from "./Screens/SingleLiveVis";
import ThreeColdest from "./Screens/ThreeColdest";
import TeamLiveVis from "./Screens/TeamLiveVis";

function App() {
  return (
    <React.Fragment>
      <Global
        styles={[
          css`
            .toast {
              width: 500px;
              margin-left: -100px;
            }
          `,
        ]}
      ></Global>

      <Router>
        <Switch>
          <Route exact path={"/"}>
            <LoginScreen />
          </Route>

          <Route exact path="/users">
            <UserListScreen />
          </Route>

          <Route exact path="/static_bar/:ids/:usernames">
            <StaticBarScreen />
          </Route>

          <Route exact path="/animated_hr/:ids/:usernames">
            <PlaybackSimpleRateScreen />
          </Route>

          <Route exact path="/live_hr_overlay/:ids/:usernames">
            <LiveSimpleRateScreen />
          </Route>

          {/* <Route exact path="/live_hr_meter/:ids/:usernames">
            <StaticBarScreen />
          </Route> 
            Got purged :(
          */}

          <Route exact path="/live_table/:ids/:usernames">
            <LiveTableScreen />
          </Route>

          <Route exact path="/live_temperature_table/:ids/:usernames">
            <LiveTemperatureTableScreen />
          </Route>

          <Route exact path="/single_live_vis/:ids/:usernames">
            <SingleLiveVis />
          </Route>

          <Route exact path="/team_live_vis">
            {/*/:ids/:usernames">*/}
            <TeamLiveVis />
          </Route>

          <Route exact path="/three_coldest">
            {/*/:ids/:usernames">*/}
            <ThreeColdest />
          </Route>

          <Route exact path="/live_graph/:ids/:usernames">
            <LiveGraphScreen />
          </Route>
        </Switch>
      </Router>

      <ToastContainer position="bottom-center" toastClassName="toast" />
    </React.Fragment>
  );
}

export default hot(App);

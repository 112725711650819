/** @jsx jsx */
import { jsx } from "@emotion/core";
import {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  RefForwardingComponent,
} from "react";
import styled from "@emotion/styled";
import { motion, useAnimation, TargetAndTransition } from "framer-motion";

const CircularDiv = styled(motion.div)`
  width: 6px;
  height: 6px;
  border-radius: 6px;

  position: absolute;
`;

function coordinatesBasedOnAngle(angle: number, r: number) {
  return {
    x: r * Math.cos(angle),
    y: r * Math.sin(angle),
  };
}

interface LittleCircle {
  x: number;
  y: number;
}

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function CircleAnimation(props: any, ref: any) {
  let [color, setColor] = useState(false);
  let animation = useAnimation();
  let uuid = useRef(uuidv4());

  useImperativeHandle<unknown, Handle>(
    ref,
    () => ({
      animationStart: (definition: TargetAndTransition | string[]) => {
        return animation.start(definition);
      },
    }),
    []
  );

  return (
    <CircularDiv
      key={uuid.current}
      animate={animation}
      transition={{
        type: "tween",
      }}
    />
  );
}

export interface Handle {
  animationStart: (definition: TargetAndTransition) => Promise<any>;
}

export default forwardRef(CircleAnimation);

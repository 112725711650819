/** @jsx jsx */
import { jsx } from "@emotion/core";
import {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  createRef,
  useCallback,
} from "react";
import styled from "@emotion/styled";
import { motion, useAnimation, TargetAndTransition } from "framer-motion";
import CircleAnimation, { Handle } from "./CircleAnimation";
import { setTimeout } from "timers";
import React from "react";

const RateCard = styled.div`
  width: 870px;
  height: 135px;
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 160px;
  align-items: center;
  position: relative;
  line-height: 20px;

  font-family: "Russo One", sans-serif;
  color: white;
`;

const BPMInfo = styled.div`
  /* background: #00000022; */
  /* background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5) 30%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0) 80%,
    rgba(0, 0, 0, 0) 100%
  ); */

  border-radius: 140px;

  width: 140px;
  height: 135px;
  z-index: 20;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* margin-left: 23px;
  margin-top: 5px; */
  position: absolute;
  left: 0px;
`;

const Circles = styled.div`
  position: absolute;
  left: 0px;
`;

function coordinatesBasedOnAngle(angle: number, r: number, scale?: number) {
  return {
    x: r * Math.cos(angle) * (scale || 1) + 67,
    y: r * Math.sin(angle) * (scale || 1) - 3,
  };
}

const CIRCLE_DENSITY = 10;

var percentColors = [
  { pct: 0.0, color: { r: 0x1a, g: 0xcf, b: 0xb6 } },
  { pct: 0.5, color: { r: 0xfd, g: 0xcf, b: 0x18 } },
  { pct: 1.0, color: { r: 0xda, g: 0x35, b: 0xd9 } },
];

function getColorForPercentage(pct: number) {
  for (var i = 1; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
      break;
    }
  }
  var lower = percentColors[i - 1];
  var upper = percentColors[i];
  var range = upper.pct - lower.pct;
  var rangePct = (pct - lower.pct) / range;
  var pctLower = 1 - rangePct;
  var pctUpper = rangePct;
  var color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
  };
  return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
  // or output as hex if preferred
}

function OuterCircle(props: {
  offsetBase: number;
  bpm: number;
  scale?: number;
  shouldNotPulse?: boolean;
}) {
  let currentBpm = useRef(props.bpm); //Pra não ressetar o setTimeout; assim eu troco e ele acelera smooth
  let littleCirclesRef = useRef<any[]>(
    new Array(CIRCLE_DENSITY).fill(0).map((_) => createRef<any>())
  );

  let animateRotating = useCallback((offset: number = 0, initial?: boolean) => {
    /**
     * 80 -> 0
     * 130 -> 50
     */
    let RANDOM_HEIGHT = currentBpm.current - 80;

    if (RANDOM_HEIGHT < 0) RANDOM_HEIGHT = 0;

    const MAX_HEIGHT = 50;
    const MIN_HEIGHT = 40;
    let animationArray = [];
    const CURRENT_SCALE_FACTOR =
      ((props.scale || 1) * currentBpm.current) / 80 / 4 + 0.75; //scale as bpm goes up, also has a lower bound of 0.75 base plus bpm
    //so it doesn't become extremely aggressive
    for (let i = 0; i < CIRCLE_DENSITY; i++) {
      let circleAnimationRef: Handle = littleCirclesRef.current[i].current;

      animationArray.push(async () => {
        let color = getColorForPercentage((currentBpm.current - 80) * 0.02);

        if (!circleAnimationRef) return;

        circleAnimationRef.animationStart({
          backgroundColor: color,
        });

        let baseAnimation: TargetAndTransition = {
          transition: {
            type: "tween",
            duration: 80 / (currentBpm.current * 6),
          },

          scale: CURRENT_SCALE_FACTOR,
        };

        if (!props.shouldNotPulse) {
          await circleAnimationRef.animationStart({
            ...baseAnimation,
            ...coordinatesBasedOnAngle(
              (Math.PI * 2 * (i + 1 / 6)) / CIRCLE_DENSITY + offset,
              MAX_HEIGHT + RANDOM_HEIGHT,
              CURRENT_SCALE_FACTOR
            ),
          });

          await circleAnimationRef.animationStart({
            ...baseAnimation,
            ...coordinatesBasedOnAngle(
              (Math.PI * 2 * (i + 2 / 6)) / CIRCLE_DENSITY + offset,
              MIN_HEIGHT - RANDOM_HEIGHT / 3,
              CURRENT_SCALE_FACTOR
            ),
          });

          await circleAnimationRef.animationStart({
            ...baseAnimation,
            ...coordinatesBasedOnAngle(
              (Math.PI * 2 * (i + 3 / 6)) / CIRCLE_DENSITY + offset,
              MAX_HEIGHT + RANDOM_HEIGHT,
              CURRENT_SCALE_FACTOR
            ),
          });

          await circleAnimationRef.animationStart({
            ...baseAnimation,
            ...coordinatesBasedOnAngle(
              (Math.PI * 2 * (i + 4 / 6)) / CIRCLE_DENSITY + offset,
              MIN_HEIGHT - RANDOM_HEIGHT / 3,
              CURRENT_SCALE_FACTOR
            ),
          });

          await circleAnimationRef.animationStart({
            ...baseAnimation,
            ...coordinatesBasedOnAngle(
              //Não sei porque mas se botar 5/6 aqui ele só volta no começo da prox anim
              (Math.PI * 2 * (i + 4 / 6)) / CIRCLE_DENSITY + offset,
              MAX_HEIGHT + RANDOM_HEIGHT,
              CURRENT_SCALE_FACTOR
            ),
          });
        } else {
          let shouldNotPulseBase: TargetAndTransition = {
            ...baseAnimation,
            transition: {
              duration: 80 / currentBpm.current,
            },
          };
          await circleAnimationRef.animationStart({
            ...shouldNotPulseBase,
            ...coordinatesBasedOnAngle(
              (Math.PI * 2 * (i + 1 / 6)) / CIRCLE_DENSITY + offset,
              MAX_HEIGHT * CURRENT_SCALE_FACTOR,
              CURRENT_SCALE_FACTOR
            ),
          });
        }
      });
    }

    animationArray.forEach((f) => f());
  }, []);

  useEffect(() => {
    currentBpm.current = props.bpm;
  }, [props.bpm]);

  useEffect(() => {
    let offsetStep = 1;
    let offsetBase = Math.PI / 10;
    let timeout: NodeJS.Timeout;

    function doAnimation() {
      animateRotating(offsetStep * offsetBase + props.offsetBase);
      offsetStep += 1;

      timeout = setTimeout(() => {
        doAnimation();
      }, 1000 / ((currentBpm.current || 80) / 80));
    }

    doAnimation();

    return () => clearTimeout(timeout);
  }, []);

  return (
    <React.Fragment>
      {new Array(CIRCLE_DENSITY).fill(0).map((_, i) => (
        <CircleAnimation key={i} ref={littleCirclesRef.current[i]} />
      ))}
      {/* <CircleAnimation ref={littleCirclesRef.current[0]} />
      <CircleAnimation ref={littleCirclesRef.current[1]} /> */}
    </React.Fragment>
  );
}

export default function BPMCard({
  bpm,
  username,
}: {
  bpm: number;
  username: string;
}) {
  return (
    <RateCard>
      <BPMInfo>
        {bpm != 0 && (
          <Circles>
            <OuterCircle bpm={bpm} offsetBase={0} />
            <OuterCircle bpm={bpm} offsetBase={Math.PI / 10} />
            <OuterCircle bpm={bpm} offsetBase={Math.PI / 20} scale={0.9} />

            <OuterCircle bpm={bpm} offsetBase={Math.PI / 6} scale={-0.3} />
            <OuterCircle bpm={bpm} offsetBase={Math.PI / 9} scale={-0.3} />
            <OuterCircle bpm={bpm} offsetBase={Math.PI / 12} scale={-0.6} />
            <OuterCircle bpm={bpm} offsetBase={Math.PI / 11} scale={-0.7} />
          </Circles>
        )}
        <span style={{ fontSize: 32, zIndex: 9999, marginTop: 10 }}>{bpm}</span>
        <span style={{ zIndex: 9999 }}>BPM</span>
      </BPMInfo>

      <span
        style={{
          fontSize: 42,
          marginLeft: 44,
          textAlign: "left",
          lineHeight: "42px",
        }}
      >
        {username.toLocaleUpperCase()}
      </span>
    </RateCard>
  );
}

import moment from "moment-timezone";

/**
 * This module manually add hours to easy showing the dates.
 *
 * Dates in JS are horrible.
 */

let offset = new Date().getTimezoneOffset() / 60;

export function setOffset(off: number) {
  let currentClientOffset = new Date().getTimezoneOffset() / 60;
  offset = off + currentClientOffset;
  console.log({ offset });
}

export function getOffset() {
  return offset;
}

export function momentOff(...args: any[]) {
  return moment(...args).add(offset, "hours");
}

//Necessary to keep queries working
export function revertOffset(date: Date) {
  return moment(date).subtract(offset, "hours");
}

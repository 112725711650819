import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import socket from "../utils/SocketHelper";
import IFrameResizer from "iframe-resizer-react";

const CURRENT_URL = "http://localhost:3051";  //LOCAL
//const CURRENT_URL = "https://api-http.telemetry.aphetor.org"; //SERVER

interface PropType {}

const IFrame = styled.iframe`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

function useInterval(callback: any, delay: number) {
  const savedCallback = useRef<any>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

interface ObjType {
  NAME: string;
  INSTA: string;
  TEMPERATURE: number;
}

const defaultValues = [
  { NAME: "Name1", INSTA: "insta1", TEMPERATURE: 35 },
  { NAME: "Name2", INSTA: "insta2", TEMPERATURE: 36 },
  { NAME: "Name3", INSTA: "insta3", TEMPERATURE: 37 },
];

export default function ThreeColdest(props: PropType) {
  let params = useParams<{ ids: string; usernames: string }>();
  const id = params.ids;
  const username = params.usernames;
  let urlSearchParams = new URLSearchParams(window.location.search);
  const accessToken = urlSearchParams.get("accessToken");
  let iframeRef = useRef<HTMLIFrameElement | null>(null);

  let [allValues, setAllValues] = useState<ObjType[]>([]);
  let [threeColdestValues, setThreeColdestValues] = useState(defaultValues);

  let allValuesRef = useRef<ObjType[]>([]);

  useEffect(() => {
    //socket.emit("selectedUserId", { userId: id, accessToken: accessToken });
    socket.on(
      "allTemperatures",
      (wsData: { temperature: number; userId: number; username: string }) => {
        console.log("WS DATA ==>", wsData);
        const { temperature, userId, username } = wsData;
        const name = username.split(" - ")[0];
        const insta = username.split(" - ")[1] ? username.split(" - ")[1] : "";
        const objToBeInserted = {
          NAME: name,
          INSTA: insta,
          TEMPERATURE: temperature,
        };

        let newAllValuesMap: { [username: string]: ObjType } = {};
        allValuesRef.current.forEach((value) => {
          newAllValuesMap[value.NAME] = value;
        });

        newAllValuesMap[objToBeInserted.NAME] = objToBeInserted;

        let newAllValues = Object.keys(newAllValuesMap).map(
          (valueName) => newAllValuesMap[valueName]
        );

        console.log({ oldAllValues: [...allValuesRef.current], newAllValues });

        allValuesRef.current = newAllValues;
        setAllValues(newAllValues);
      }
    );

    return () => {
      socket.off("temperature");
    };
  }, []);

  useEffect(() => {
    if (allValues.length > 3) {
      let sortedAllValues = allValues.sort((a, b) =>
        a.TEMPERATURE > b.TEMPERATURE ? 1 : -1
      );
      let firstThree = sortedAllValues.slice(0, 3);
      setThreeColdestValues(firstThree);
      console.log("COLDEST VALUES =>", firstThree);
    } else {
      setThreeColdestValues(allValues);
    }
  }, [allValues]);

  useInterval(() => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage(
        {
          value: threeColdestValues,
        },
        CURRENT_URL
      );
    }
    //console.log("ALL VALUES =>", allValues);
  }, 1000);

  return (
    <IFrame
      id={"myiframe"}
      ref={iframeRef}
      allowTransparency={true}
      src={`${CURRENT_URL}/animations/temp-top3-coldest.html`}
    />
  );
}

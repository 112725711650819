/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";

import HighchartsReact from "highcharts-react-official";
import Highcharts, { chart, dateFormat } from "highcharts";

import moment from "moment-timezone";
import socket from "../utils/SocketHelper";
import { useParams, useHistory } from "react-router-dom";
import { parse } from "query-string";
import { toast } from "react-toastify";
import { momentOff } from "../utils/MomentWithOffset";

export interface Entry {
  _id: string;
  date: string;
  google_id: string;
  value: number;
}

const UserArea = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const UserImage = styled.img`
  width: 300px;
  height: 100%;
  object-fit: contain;
`;

const UserName = styled.span`
  font-weight: bold;
  text-align: center;

  margin-top: 25px;
  font-size: 18px;
`;

const MaxMinArea = styled.div`
  display: flex;

  justify-content: center;
  align-items: flex-start;

  margin-left: 100px;

  flex-direction: column;

  width: 100%;
  height: 100%;

  font-size: 40px;
`;

/**
 * Function that maps each entry from the array to one second of time
 * @param startDate
 * @param endDate
 * @param entries
 */
export function mapEachEntryToOneSecond(
  startDate: string,
  endDate: string,
  entries: Entry[]
) {
  let startDateMoment = moment(startDate);
  let endDateMoment = moment(endDate);
  let dateDiffInSeconds = endDateMoment.diff(startDateMoment, "seconds");

  //creates all seconds between two dates
  let arrayOfDateValues: { date: moment.Moment; value: number }[] = [];
  let dateIterator = startDateMoment;
  for (let i = 0; i <= dateDiffInSeconds; i++) {
    arrayOfDateValues.push({
      value: 0,
      date: moment(dateIterator),
    });

    dateIterator = dateIterator.add(1, "second");
  }

  //for performance reasons, start from the last index, because the array is ordered by time
  let j = 0;
  for (let i = 0; i < entries.length; i++) {
    let entry = entries[i];
    //unique key representing this entry second
    let entryMomentDate = moment(entry.date).format("DD/MM/YYYY HH:mm:ss");

    for (j; j < arrayOfDateValues.length; j++) {
      let dateValue = arrayOfDateValues[j];
      //unique key representing this second
      let dateValueMomentDate = dateValue.date.format("DD/MM/YYYY HH:mm:ss");

      //if they're equal, go for it
      if (dateValueMomentDate == entryMomentDate) {
        dateValue.value = entry.value;
        break;
      }
    }
  }

  //do a filter to fill fragments of zeroes between two seconds, a side-effect of mapping to each second
  for (let i = 4; i < arrayOfDateValues.length - 5; i++) {
    //hardcoded to be fast
    let dateValue = arrayOfDateValues[i];
    let dateBefore1 = arrayOfDateValues[i - 1];
    let dateAfter1 = arrayOfDateValues[i + 1];
    let dateBefore2 = arrayOfDateValues[i - 2];
    let dateAfter2 = arrayOfDateValues[i + 2];
    let dateBefore3 = arrayOfDateValues[i - 3];
    let dateAfter3 = arrayOfDateValues[i + 3];
    let dateBefore4 = arrayOfDateValues[i - 4];
    let dateAfter4 = arrayOfDateValues[i + 4];
    let dateBefore5 = arrayOfDateValues[i - 5];
    let dateAfter5 = arrayOfDateValues[i + 5];

    //if it's a zero between two filled values, fill it with a duplicate of the last value
    if (dateValue.value == 0) {
      let dateBeforeValue =
        dateBefore1?.value ||
        dateBefore2?.value ||
        dateBefore3?.value ||
        dateBefore4?.value ||
        dateBefore5?.value;

      let dateAfterValue =
        dateAfter1?.value ||
        dateAfter2?.value ||
        dateAfter3?.value ||
        dateAfter4?.value ||
        dateAfter5?.value;

      if (dateBeforeValue && dateAfterValue) {
        dateValue.value = dateBeforeValue;
      }
    }
  }

  let arrayOfDateValuesDateFormatted = arrayOfDateValues.map((dateValue) => ({
    value: dateValue.value,
    date: momentOff(dateValue.date).format("HH:mm:ss"),
  }));

  return arrayOfDateValuesDateFormatted;
}

function StaticBarScreen() {
  let params = useParams<{
    ids: string;
    usernames: string;
    start: string;
    end: string;
  }>();
  let history = useHistory();

  let [currentHeartrate, setCurrentHeartRate] = useState(0);
  let maxHeartRateRef = useRef(0);
  let minHeartRateRef = useRef(999);
  let [maxHeartrate, setMaxHeartRate] = useState(0);
  let [minHeartRate, setMinHeartRate] = useState(999);

  const ids = params.ids.split(",");
  const usernames = params.usernames.split(",");

  let queryObject = parse(window.location.search) as {
    showGreenScreen?: string;
    start?: string;
    end?: string;
    accessToken?: string;
  };

  console.log({ queryObject });

  let showGreenScreen = queryObject.showGreenScreen == "true";
  let startDate = moment(queryObject.start);
  let endDate = moment(queryObject.end);

  let [chartOptions, setChartOptions] = useState<Highcharts.Options>({
    title: {
      text: "",
    },
    chart: {
      animation: false,
      height: 300,
      width: 800,
      backgroundColor: showGreenScreen ? "#00ff00" : "transparent",
    },
    xAxis: {
      categories: [],
      tickInterval: 1,
      labels: {
        rotation: -45,
      },
    },
    yAxis: {
      max: 150,
      min: 50,
    },
    series: [
      {
        type: "spline",
        data: [],
        color: "red",
        name: "heartrate",
      },
    ],
    plotOptions: {
      series: {
        animation: false,
        marker: {
          enabled: false,
        },
      },
      line: {
        animation: false,
        marker: {
          enabled: false,
        },
      },
    },
  });

  useEffect(() => {
    console.log({ startDate, endDate });
    socket.emit("getEntriesForUser", {
      id: ids[0],
      start: startDate,
      end: endDate,
    });

    console.log(
      JSON.stringify({
        id: ids[0],
        start: startDate,
        end: endDate,
      })
    );

    socket.on("getEntriesForUserResponse", (entries: Entry[]) => {
      console.log({ entries });
      let data = (chartOptions.series as any)[0].data;
      let categories = (chartOptions.xAxis as any).categories;

      if (!entries[0]) {
        toast.error(
          `No entries for the user ${usernames[0]} at this selected time!`
        );
        return history.goBack();
      }

      let firstEntryDate = entries[0].date;
      let lastEntryDate = entries[entries.length - 1].date;
      let mappedEntries = mapEachEntryToOneSecond(
        firstEntryDate,
        lastEntryDate,
        entries
      );

      console.log({
        mappedEntries,
      });

      mappedEntries.forEach((entry, index) => {
        categories.push(entry.date);

        data.push(entry.value);

        if (entry.value > maxHeartRateRef.current) {
          maxHeartRateRef.current = entry.value;
        }

        if (entry.value < minHeartRateRef.current && entry.value != 0) {
          minHeartRateRef.current = entry.value;
        }
      });

      setMaxHeartRate(maxHeartRateRef.current);
      setMinHeartRate(minHeartRateRef.current);

      // const BATCH = 20;
      // let samplesPerBatch = Math.floor(entries.length / BATCH);
      // let average: { date: string; value: number }[] = [];

      // for (let i = 0; i < BATCH; i++) {
      //   let averageItem = {
      //     date: entries[i * samplesPerBatch].date,
      //     value: 0,
      //   };

      //   let j = 0;
      //   for (j = 0; j < samplesPerBatch; j++) {
      //     //console.log(i * samplesPerBatch + j);
      //     averageItem.value += entries[i * samplesPerBatch + j].value;
      //   }

      //   if (i == BATCH) {
      //     let k = 0;
      //     for (let k = i * samplesPerBatch; k < entries.length; k++) {
      //       averageItem.value += entries[k].value;
      //     }

      //     averageItem.value /= j + k + 1;
      //   } else {
      //     averageItem.value /= j + 1;
      //   }

      //   averageItem.value = Math.floor(averageItem.value * 100) / 100;

      //   average.push(averageItem);
      // }

      //console.log(average);

      // average.forEach((item, index) => {
      //   data.push(item.value);
      //   categories.push(index % 2 ? "" : momentOff(item.date).format("HH:mm:ss"));

      //   setMaxHeartRate((max) => {
      //     if (item.value > max) return item.value;
      //     else return max;
      //   });
      //   setMinHeartRate((min) => {
      //     if (item.value < min) return item.value;
      //     else return min;
      //   });
      // });

      // let lastEntry = entries[entries.length - 1];
      // data.push(lastEntry.value);
      // categories.push(momentOff(lastEntry.date).format("HH:mm:ss"));

      //categories[categories.length - 1] = momentOff().format("HH:mm:ss");

      setChartOptions({
        xAxis: {
          categories,
          tickInterval: Math.round(mappedEntries.length / 10),
        },
        series: [
          {
            type: "line",
            data,
            color: "#C62121",
          },
        ],
      });
    });

    return () => {
      socket.off("getEntriesForUserResponse");
    };
  }, []);

  console.log({ showGreenScreen });

  return (
    <div
      className="App"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        flexDirection: "column",
        backgroundColor: showGreenScreen ? "#00ff00" : "transparent",
      }}
    >
      <div
        css={css`
          display: flex;
          justify-content: row;
          margin-bottom: 100px;
        `}
      >
        <UserArea>
          <UserImage src="https://captaprojetos.com.br/wp-content/uploads/2014/02/avatar-placeholder1.png"></UserImage>

          <UserName> {usernames[0]}</UserName>
        </UserArea>

        <MaxMinArea>
          <span style={{ marginBottom: 10 }}>
            <b>max:</b> {maxHeartrate.toFixed(1)}bpm
          </span>
          <span>
            <b>min:</b> {minHeartRate.toFixed(1)}bpm
          </span>
        </MaxMinArea>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        ></div>
      </div>
    </div>
  );
}

export default StaticBarScreen;

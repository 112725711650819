import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import socket from "../utils/SocketHelper";
import IFrameResizer from "iframe-resizer-react";

//export const CURRENT_URL = "http://localhost:3051";
export const CURRENT_URL = "https://api-http.telemetry.aphetor.org";

interface PropType {}

interface HeartrateAnimObj {
  BPM: number;
  NAME: string;
  INSTA: string;
}

interface TemperatureAnimObj {
  TEMPERATURE: number;
  NAME: string;
  INSTA: string;
}

const IFrame = styled.iframe`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px !important;
`;

const options = [
  "animation",
  "heart-value-only",
  "heart-16x9-lower-third",
  "heart-9x16-lower-third",
  "heart-16x9-full-screen",
  "heart-9x16-full-screen",
  "temp-value-only",
  "temp-9x16-lower-third",
  "temp-16x9-full-screen",
  "temp-9x16-full-screen",
  "temp-16x9-lower-third",
];

export function useInterval(callback: any, delay: number) {
  const savedCallback = useRef<any>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function SingleLiveVis(props: PropType) {
  let params = useParams<{ ids: string; usernames: string }>();
  const id = params.ids;
  const username = params.usernames;
  let urlSearchParams = new URLSearchParams(window.location.search);
  const accessToken = urlSearchParams.get("accessToken");
  let iframeRef = useRef<HTMLIFrameElement | null>(null);

  let type = urlSearchParams.get("type");
  if (type === "temperature") type = "temp";
  let socketChannel = type === "heart" ? "heartrate" : "temperature";

  let orientation = urlSearchParams.get("orientation");

  let [min, setMin] = useState(0);
  let [max, setMax] = useState(0);

  let [currentValue, setCurrentValue] = useState<any>(
    type === "heart"
      ? {
          BPM: 0,
          BPM_MAX: 0,
          BPM_MIN: 0,
          NAME: username.split(" - ")[0],
          INSTA: username.split(" - ")[1] ? username.split(" - ")[1] : "",
        }
      : {
          TEMPERATURE: 0,
          TEMPERATURE_MIN: 0,
          NAME: username.split(" - ")[0],
          INSTA: username.split(" - ")[1] ? username.split(" - ")[1] : "",
        }
  );

  useEffect(() => {
    socket.emit("selectedUserId", { userId: id, accessToken: accessToken });
    if (socketChannel === "heartrate") {
      socket.on(
        "findHRMaxMinResponse",
        (wsData: { BPM_MAX: number; BPM_MIN: number }) => {
          console.log("findHRMaxMinResponse", { wsData });
          // setMax(wsData.BPM_MAX);
          // setMin(wsData.BPM_MIN);
          let max = wsData.BPM_MAX;
          let min = wsData.BPM_MIN;
          setCurrentValue((previousValue: any) => {
            return { ...previousValue, BPM_MAX: max, BPM_MIN: min };
          });
        }
      );
    } else {
      socket.on(
        "findTempMinResponse",
        (wsData: { TEMPERATURE_MIN: number }) => {
          console.log("findTempMinResponse", { wsData });
          //setMin(wsData.TEMPERATURE_MIN);
          let min = wsData.TEMPERATURE_MIN;
          setCurrentValue((previousValue: any) => {
            return { ...previousValue, TEMPERATURE_MIN: min };
          });
        }
      );
    }
    socketChannel &&
      socket.on(
        socketChannel,
        (wsData: {
          temperature: number;
          heartrate: number;
          userId: number;
          username: string;
          currentHRSession: number;
          currentTemperatureSession: number;
        }) => {
          console.log(`${socketChannel} socket`);
          console.log({ wsData });
          const value =
            socketChannel === "heartrate"
              ? wsData.heartrate
              : wsData.temperature;
          const username = wsData.username;
          const name = username.split(" - ")[0];
          const insta = username.split(" - ")[1]
            ? username.split(" - ")[1]
            : "";
          const objToSet =
            socketChannel === "heartrate"
              ? {
                  BPM: value,
                  // BPM_MAX: max,
                  // BPM_MIN: min,
                  NAME: name,
                  INSTA: insta,
                }
              : {
                  TEMPERATURE: value,
                  //TEMPERATURE_MIN: min,
                  NAME: name,
                  INSTA: insta,
                };
          if (socketChannel === "heartrate") {
            socket.emit("findHRMaxMin", {
              reading: {
                google_id: wsData.userId,
                session: wsData.currentHRSession,
              },
              accessToken,
            });
          } else {
            socket.emit("findTempMin", {
              reading: {
                user_id: wsData.userId,
                session: wsData.currentTemperatureSession,
              },
              accessToken,
            });
          }
          console.log(objToSet);
          setCurrentValue((previousValue: any) => {
            return Object.assign(previousValue, objToSet);
          });
        }
      );

    return () => {
      socketChannel && socket.off(socketChannel);
    };
  }, []);

  useInterval(() => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage(
        {
          value: currentValue,
        },
        CURRENT_URL
      );
    }
  }, 1000);

  return (
    <IFrame
      id={"myiframe"}
      ref={iframeRef}
      allowTransparency={true}
      src={`${CURRENT_URL}/animations/${type}-${orientation}-full-screen.html`}
    />
  );
}

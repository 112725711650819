import React, { useState } from "react";
import styled from "@emotion/styled";
import logo from "../assets/aphetor_logo.png";
import socket from "../utils/SocketHelper";
import { css } from "@emotion/core";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { type } from "os";
import { toast } from "react-toastify";

const CustomCheckbox = withStyles({
  root: {
    color: "black",
    "&$checked": {
      color: "black",
    },
  },
  checked: {},
})((props: any) => <Checkbox color="default" {...props} />);

const CustomFormControlLabel = withStyles({
  label: {
    fontSize: 14,
  },
})(FormControlLabel);

const Container = styled.div`
  height: 50vh;
  width: 30vw;
  background-color: white;
  border-color: black;
  border-style: solid;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const LogoContainer = styled.div`
  height: 40%;
  width: 80%;
`;

const FormInput = styled.input`
  width: 80%;
  height: 45px;
`;

const PasswordInput = styled.input`
  width: 80%;
  height: 45px;

  -webkit-text-security: disc;
`;

const CheckArea = styled.div`
  width: 80%;
  height: 45px;

  background-color: lightgreen;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const FormButton = styled.button`
  width: 80%;
  height: 45px;
  background-color: black;
  border-color: black;
`;

interface PropType {}

const handleLogin = (username: string, password: string) => {
  socket.emit("loginAdmin", { name: username, password });
};

const LoginForm = (props: PropType) => {
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [remember, setRemember] = useState(
    localStorage.getItem("remember") ? localStorage.getItem("remember") : false
  );

  return (
    <Container>
      <LogoContainer style={{ marginTop: "10%" }}>
        <img src={logo} />
      </LogoContainer>
      <FormInput
        placeholder={"Username"}
        style={{ textAlign: "center" }}
        value={username}
        onChange={(event) => {
          setUsername(event.target.value);
        }}
      />
      <PasswordInput
        placeholder={"Password"}
        style={{ textAlign: "center" }}
        value={password}
        onChange={(event) => {
          setPassword(event.target.value);
        }}
      />
      <CustomFormControlLabel
        control={
          <CustomCheckbox
            checked={remember}
            onChange={(event: any) => {
              setRemember(event.target.checked);
              localStorage.setItem("remember", event.target.checked);
            }}
            name="checkedG"
          />
        }
        label="Remember me"
      />
      <FormButton
        className="button"
        style={{ marginBottom: "10%" }}
        onClick={() =>
          username && password
            ? handleLogin(username, password)
            : toast.error("Enter a User ID and password!")
        }
      >
        Log In
      </FormButton>
    </Container>
  );
};

export default LoginForm;

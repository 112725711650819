/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import GoogleLogin from "react-google-login";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import socket from "../utils/SocketHelper";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import LoginForm from "../Components/LoginForm";

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MainCard = styled.div``;

export default function LoginScreen() {
  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    socket.on("loginAdminResponse", (data: { accessToken: string }) => {
      console.log(data);
      if (data.accessToken) {
        localStorage.setItem("accessToken", data.accessToken);
        history.replace(`/users?accessToken=${data.accessToken}`);
      } else {
        history.push("/");
        toast.error("This account is not an admin!");
      }
    });

    if (localStorage.getItem("accessToken") != "null")
      history.replace(
        `/users?accessToken=${localStorage.getItem("accessToken")}`
      );
  }, []);

  let hasExecutedGoogleLogin = false;
  if (window.location.href.includes("#scope=")) {
    let idToken = window.location.href
      .split("&id_token=")[1]
      .split("&login_hint")[0];

    let userObject: { sub: string } = jwtDecode(idToken);

    let userGoogleId = userObject.sub;

    socket.emit("loginAdmin", { google_id: userGoogleId });
    console.log("logged in", window.location.href, userObject, userGoogleId);
  }

  return (
    <Root>
      {/* <div>
        <GoogleLogin
          css={css`
            width: 200px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;

            font-weight: bold;

            * > svg {
              margin-top: 15px;
            }
          `}
          uxMode="redirect"
          clientId="102354411972-3mko7h74apg1gcdq3vl7vhh85r6cni9p.apps.googleusercontent.com"
          buttonText="Login"
          onSuccess={(data: any) => {
            console.log({ data });
            let googleId = data.googleId;
            console.log("GOOGLE ID ======>", googleId);
            socket.emit("loginAdmin", { google_id: googleId });
            //sessionStorage.setItem("google_id_admin");
          }}
          onFailure={console.log}
          cookiePolicy={"single_host_origin"}
        />
      </div> */}
      <LoginForm />
    </Root>
  );
}

/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useState, useEffect } from "react";

import HighchartsReact from "highcharts-react-official";
import Highcharts, { chart, dateFormat } from "highcharts";

import moment from "moment-timezone";
import socket from "../utils/SocketHelper";
import { useParams, useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { parse } from "query-string";
//import LottieHeart from "../Components/LottieHeart";
import { ReactComponent as HeartIconSVG } from "../heart.svg";
import { motion, useAnimation } from "framer-motion";
import { Entry, mapEachEntryToOneSecond } from "./StaticBarScreen";
import ReactDOM from "react-dom";
import BPMCard from "../Components/BPMCard";
import { AbsoluteDiv } from "./LiveSimpleRateScreen";

interface UserTable {
  username: string;
  userId: number | string;
  lastValueRead: number;
}

const HeartRateDiv = styled.div`
  position: absolute;
  left: 60px;
  top: 90px;

  width: 400px;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OnScreenDate = styled.div`
  opacity: 0.5;
  font-size: 110px;

  position: fixed;
  right: 100px;
  bottom: 50px;
`;

function PlaybackSimpleRateScreen() {
  let params = useParams<{
    ids: string;
    usernames: string;
    start: string;
    end: string;
  }>();
  let history = useHistory();

  const ids = params.ids.split(",");
  const usernames = params.usernames.split(",");

  let [lastValueRead, setLastValueRead] = useState(0);
  let [lastTime, setLastTime] = useState(moment().format("HH:mm:ss"));

  let queryObject = parse(window.location.search) as {
    showGreenScreen?: string;
    showOnScreenDate?: string;
    start?: string;
    end?: string;
    accessToken?: string;
  };

  console.log({ queryObject });

  let showGreenScreen = queryObject.showGreenScreen == "true";
  let showOnScreenDate = queryObject.showOnScreenDate == "true";
  let startDate = queryObject.start;
  let endDate = queryObject.end;
  useEffect(() => {
    let interval: NodeJS.Timeout;
    socket.emit("getEntriesForUser", {
      id: ids[0],
      start: startDate,
      end: endDate,
    });
    socket.on("getEntriesForUserResponse", (entries: Entry[]) => {
      console.log(entries);
      if (entries.length == 0) return;
      let firstEntryDate = entries[0].date;
      let lastEntryDate = entries[entries.length - 1].date;
      let mappedEntries = mapEachEntryToOneSecond(
        firstEntryDate,
        lastEntryDate,
        entries
      );

      console.log({
        mappedEntries,
      });

      let entryIndex = 0;
      interval = setInterval(() => {
        let entry = mappedEntries[entryIndex];

        if (entry)
          ReactDOM.unstable_batchedUpdates(() => {
            setLastValueRead(entry.value);
            setLastTime(entry.date);
          });

        entryIndex++;
      }, 1000);
    });

    return () => {
      socket.off("getEntriesForUserResponse");
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      className="App"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        flexDirection: "column",
        backgroundColor: showGreenScreen ? "#00ff00" : "transparent",
        position: "relative",
      }}
    >
      {/* <HeartRateDiv>
        <motion.div
          animate={{
            scale: 1.1,
          }}
          transition={{ yoyo: Infinity }}
        >
          <HeartIconSVG width="80px" height="80px" fill="red" />
        </motion.div>

        <span
          css={css`
            font-size: 80px;
            font-weight: bold;
            color: red;

            min-width: 260px;

            border-style: solid;
            border-width: 6px;
            border-color: red;
            border-radius: 24px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 45px;
            padding-left: 45px;
          `}
        >
          {lastValueRead}
        </span>
      </HeartRateDiv> */}

      <AbsoluteDiv>
        <BPMCard
          bpm={
            // 80
            lastValueRead
          }
          username={usernames[0]}
        />
      </AbsoluteDiv>

      {showOnScreenDate && <OnScreenDate>{lastTime}</OnScreenDate>}
    </div>
  );
}

export default PlaybackSimpleRateScreen;

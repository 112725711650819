import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import socket from "../utils/SocketHelper";
import { useInterval, CURRENT_URL } from "./SingleLiveVis";

interface TeamLiveVisProps {}

interface RenderObj {
  userId: string;
  TEAM_NAME: string;
  BPM?: number;
  BPM_MIN?: number;
  BPM_MAX?: number;
  TEMPERATURE?: number;
  TEMPERATURE_MIN?: number;
  NAME: string;
  INSTA: string;
  //readings: number[];
}

const IFrame = styled.iframe`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px !important;
`;

export default function TeamLiveVis(props: TeamLiveVisProps) {
  let urlSearchParams = new URLSearchParams(window.location.search);
  const accessToken = urlSearchParams.get("accessToken");
  let iframeRef = useRef<HTMLIFrameElement | null>(null);
  let team = urlSearchParams.get("team");
  let type = urlSearchParams.get("type");
  if (type === "temperature") type = "temp";
  let socketChannel = type === "heart" ? "heartrate" : "temperature";

  let [renderObjs, setRenderObjs] = useState<RenderObj[]>([]);

  useEffect(() => {
    socket.emit("join", accessToken);

    if (socketChannel === "heartrate") {
      socket.on(
        "findHRMaxMinResponse",
        (wsData: { BPM_MAX: number; BPM_MIN: number; userId: string }) => {
          console.log("findHRMaxMinResponse", { wsData });
          // setMax(wsData.BPM_MAX);
          // setMin(wsData.BPM_MIN);
          let max = wsData.BPM_MAX;
          let min = wsData.BPM_MIN;
          setRenderObjs((renderObjs) =>
            renderObjs.map((renderObj) => {
              if (renderObj.userId == wsData.userId) {
                return {
                  ...renderObj,
                  BPM_MIN: min,
                  BPM_MAX: max,
                };
              } else {
                return renderObj;
              }
            })
          );
        }
      );
    }

    socket.on(
      "getTeamMembersResponse",
      (
        wsData: { UserID: string; username: string; temp_sensorID: string }[]
      ) => {
        console.log(wsData);
        let ids = wsData.map((member) => member.UserID);
        socket.emit("multiSelectedUserId", { ids });

        if (type === "heart") {
          setRenderObjs(
            wsData.map((member) => {
              return {
                userId: member.UserID,
                BPM: 0,
                NAME: member.username.split(" - ")[0],
                INSTA: member.username.split(" - ")[1]
                  ? member.username.split(" - ")[1]
                  : "",
                //BPM_MIN: 0,
                //BPM_MAX: 0,
                TEAM_NAME: team as string,
                //readings: [],
              };
            })
          );
        } else if (type === "temp") {
          setRenderObjs(
            wsData.map((member) => {
              return {
                userId: member.UserID,
                TEMPERATURE: 0,
                NAME: member.username.split(" - ")[0],
                INSTA: member.username.split(" - ")[1]
                  ? member.username.split(" - ")[1]
                  : "",
                //TEMPERATURE_MIN: 0,
                TEAM_NAME: team as string,
                readings: [],
              };
            })
          );
        }
      }
    );
    socket.on(
      socketChannel,
      (wsData: {
        temperature: number;
        heartrate: number;
        userId: string;
        currentHRSession: number;
      }) => {
        if (type === "heart") {
          const { heartrate, userId } = wsData;
          setRenderObjs((renderObjs) =>
            renderObjs.map((renderObj) => {
              if (renderObj.userId == userId) {
                // let currentReadings = renderObj.readings;
                // currentReadings?.push(heartrate);
                // currentReadings?.sort((a: number, b: number) => a - b);

                return {
                  ...renderObj,
                  BPM: heartrate,
                  //BPM_MIN: currentReadings[0],
                  //BPM_MAX: currentReadings[currentReadings.length - 1],
                  //readings: currentReadings,
                };
              } else {
                return renderObj;
              }
            })
          );
          socket.emit("findHRMaxMin", {
            reading: {
              google_id: wsData.userId,
              session: wsData.currentHRSession,
            },
            accessToken,
            team: true,
          });
        } else if (type === "temp") {
          const { temperature, userId } = wsData;
          setRenderObjs((renderObjs) =>
            renderObjs.map((renderObj: any) => {
              if (renderObj.userId == userId) {
                // let currentReadings = renderObj.readings;
                // currentReadings?.push(temperature);
                // currentReadings?.sort((a: number, b: number) => a - b);

                return {
                  ...renderObj,
                  TEMPERATURE: temperature,
                  //TEMPERATURE_MIN: currentReadings[0],
                  //readings: currentReadings,
                };
              } else {
                return renderObj;
              }
            })
          );
        }
      }
    );

    socket.emit("getTeamMembers", { team: team, accessToken: accessToken });
    return () => {
      socket.off("getTeamMembersResponse");
    };
  }, []);

  useEffect(() => {
    console.log("RENDER OBJS ===>", renderObjs);
  }, [renderObjs]);

  useInterval(() => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage(
        {
          value: renderObjs,
        },
        CURRENT_URL
      );
    }
  }, 1000);

  return (
    <IFrame
      id={"myiframe"}
      ref={iframeRef}
      allowTransparency={true}
      src={`${CURRENT_URL}/animations/${type}-team-table.html`}
    />
  );
}

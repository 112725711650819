import openConnection from "socket.io-client";

//let socket = openConnection("http://localhost:3050"); //LOCAL
//let socketGFX = openConnection("http://localhost:3050"); //LOCAL GFX
//let socket = openConnection("https://telemetry-api.palhari.dev");
//let socket = openConnection("http://64.227.45.195:3050");
let socket = openConnection("https://api.telemetry.aphetor.org"); //SERVER
let socketGFX = openConnection("https://gfx.telemetry.aphetor.org"); //SERVER GFX

socket.on("connection", () => {
  console.log("connected");
});

export { socketGFX as socketGFX };

export default socket;

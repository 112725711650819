/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useState, useEffect } from "react";

import HighchartsReact from "highcharts-react-official";
import Highcharts, { chart, dateFormat } from "highcharts";

import moment from "moment-timezone";
import socket from "../utils/SocketHelper";
import { useParams, useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { parse } from "query-string";

interface UserTable {
  username: string;
  userId: number | string;
  lastValueRead: number;
}

function LiveTableScreen() {
  let params = useParams<{ ids: string; usernames: string }>();
  let history = useHistory();

  const ids = params.ids.split(",");
  const usernames = params.usernames.split(",");

  let [users, setUsers] = useState<UserTable[]>(
    usernames.map((username, index) => ({
      username,
      userId: ids[index],
      lastValueRead: 0,
    }))
  );

  useEffect(() => {
    socket.emit("multiSelectedUserId", { ids });

    socket.on("heartrate", (wsData: { heartrate: number; userId: number }) => {
      const { heartrate, userId } = wsData;

      setUsers((users) =>
        users.map((user) => {
          if (user.userId == userId) {
            return {
              ...user,
              lastValueRead: heartrate,
            };
          } else {
            return user;
          }
        })
      );
    });

    return () => {
      socket.off("heartrate");
    };
  }, []);

  let queryObject = parse(window.location.search) as {
    showGreenScreen?: string;
    accessToken?: string;
  };
  let showGreenScreen = queryObject.showGreenScreen == "true";

  console.log(queryObject);

  return (
    <div
      className="App"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        flexDirection: "column",
        backgroundColor: showGreenScreen ? "#00ff00" : "transparent",
      }}
    >
      <table
        css={css`
          background-color: white;
          width: 500px;
          padding-left: 10px;
          padding-right: 10px;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
            0 6px 6px rgba(0, 0, 0, 0.23);
        `}
      >
        <thead>
          <tr>
            <th>Aphetor</th>
            <th
              css={css`
                display: flex;
                justify-content: center;
              `}
            >
              Heart-rate
            </th>
          </tr>
        </thead>

        {users.map((user) => (
          <tr>
            <td>{user.username}</td>
            <td
              css={css`
                display: flex;
                justify-content: center;
              `}
            >
              {user.lastValueRead}
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
}

export default LiveTableScreen;

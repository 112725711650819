/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useState, useEffect } from "react";

import HighchartsReact from "highcharts-react-official";
import Highcharts, { chart, dateFormat } from "highcharts";

import moment from "moment-timezone";
import socket from "../utils/SocketHelper";
import { useParams, useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { parse } from "query-string";
//import LottieHeart from "../Components/LottieHeart";
import { ReactComponent as HeartIconSVG } from "../heart.svg";
import { motion, useAnimation } from "framer-motion";
import BPMCard from "../Components/BPMCard";

interface UserTable {
  username: string;
  userId: number | string;
  lastValueRead: number;
}

const HeartRateDiv = styled.div`
  position: absolute;
  left: 60px;
  top: 90px;

  width: 400px;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AbsoluteDiv = styled.div`
  position: fixed;
  bottom: 125px;
  left: 525px;
`;

function LiveSimpleRateScreen() {
  let params = useParams<{ ids: string; usernames: string }>();
  let history = useHistory();

  const ids = params.ids.split(",");
  const usernames = params.usernames.split(",");

  let [users, setUsers] = useState<UserTable[]>(
    usernames.map((username, index) => ({
      username,
      userId: ids[index],
      lastValueRead: 0,
    }))
  );

  useEffect(() => {
    socket.emit("multiSelectedUserId", { ids });

    socket.on("heartrate", (wsData: { heartrate: number; userId: number }) => {
      const { heartrate, userId } = wsData;

      setUsers((users) =>
        users.map((user) => {
          if (user.userId == userId) {
            return {
              ...user,
              lastValueRead: heartrate,
            };
          } else {
            return user;
          }
        })
      );
    });

    return () => {
      socket.off("heartrate");
    };
  }, []);

  let queryObject = parse(window.location.search) as {
    showGreenScreen?: string;
    accessToken?: string;
  };
  let showGreenScreen = queryObject.showGreenScreen == "true";
  console.log(queryObject);

  return (
    <div
      className="App"
      style={{
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        flexDirection: "column",
        paddingBottom: "100px",
        backgroundColor: showGreenScreen ? "#00ff00" : "transparent",
        position: "relative",
      }}
    >
      {/* <HeartRateDiv>
        <motion.div
          animate={{
            scale: 1.1,
          }}
          transition={{ yoyo: Infinity }}
        >
          <HeartIconSVG width="80px" height="80px" fill="red" />
        </motion.div>

        <span
          css={css`
            font-size: 80px;
            font-weight: bold;
            color: red;

            min-width: 260px;

            border-style: solid;
            border-width: 6px;
            border-color: red;
            border-radius: 24px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 45px;
            padding-left: 45px;
          `}
        >
          {users[0].lastValueRead}
        </span>
      </HeartRateDiv> */}

      <AbsoluteDiv>
        <BPMCard
          bpm={
            // 80
            users[0].lastValueRead
          }
          username={users[0].username}
        />
      </AbsoluteDiv>
    </div>
  );
}

export default LiveSimpleRateScreen;

/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useState, useEffect } from "react";

import HighchartsReact from "highcharts-react-official";
import Highcharts, { chart, dateFormat } from "highcharts";

import moment from "moment-timezone";
import socket from "../utils/SocketHelper";
import { useParams, useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { parse } from "query-string";

interface UserTable {
  username: string;
  userId: number | string;
  lastValueRead: number;
}

function LiveTemperatureTableScreen() {
  let params = useParams<{ ids: string; usernames: string }>();
  let history = useHistory();

  const ids = params.ids.split(",");
  const usernames = params.usernames.split(",");

  let [users, setUsers] = useState<UserTable[]>(
    usernames.map((username, index) => ({
      username,
      userId: ids[index],
      lastValueRead: 0,
    }))
  );

  let [lastUpdated, setLastUpdated] = useState(new Date());

  useEffect(() => {
    console.log("IDS =", ids);
    socket.emit("multiSelectedUserId", { ids });
    socket.emit("tableUpdate");

    socket.on(
      "temperature",
      (wsData: { temperature: number; userId: number }) => {
        console.log("WS DATA ==>", wsData);
        const { temperature, userId } = wsData;

        setUsers((users) =>
          users.map((user) => {
            if (user.userId == userId) {
              return {
                ...user,
                lastValueRead: temperature,
              };
            } else {
              return user;
            }
          })
        );
      }
    );

    socket.on("tableUpdateResponse", (wsData: { lastUpdated: Date }) => {
      console.log("UPDATED LAST ==>", wsData.lastUpdated.toString());
      setLastUpdated(new Date(wsData.lastUpdated));
    });

    return () => {
      socket.off("temperature");
      socket.off("tableUpdateResponse");
    };
  }, []);

  let queryObject = parse(window.location.search) as {
    showGreenScreen?: string;
    accessToken?: string;
  };
  let showGreenScreen = queryObject.showGreenScreen == "true";

  //console.log(queryObject);
  console.log(users);

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <div
        className="App"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "90vh",
          flexDirection: "column",
          backgroundColor: showGreenScreen ? "#00ff00" : "transparent",
        }}
      >
        <table
          css={css`
            background-color: white;
            width: 500px;
            padding-left: 10px;
            padding-right: 10px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
              0 6px 6px rgba(0, 0, 0, 0.23);
          `}
        >
          <thead>
            <tr>
              <th>Aphetor</th>
              <th
                css={css`
                  display: flex;
                  justify-content: center;
                `}
              >
                Temperature
              </th>
            </tr>
          </thead>

          {users.map((user) => (
            <tr>
              <td>{user.username}</td>
              <td
                css={css`
                  display: flex;
                  justify-content: center;
                `}
              >
                {user.lastValueRead}
              </td>
            </tr>
          ))}
        </table>
      </div>
      <div
        style={{
          width: "100vw",
          height: "10vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <text>{`Last Updated at ${lastUpdated.getHours()}h${
          (lastUpdated.getMinutes() < 10 ? "0" : "") + lastUpdated.getMinutes()
        }`}</text>
      </div>
    </div>
  );
}

export default LiveTemperatureTableScreen;

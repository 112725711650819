/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useEffect, useState, useRef } from "react";
import socket, { socketGFX } from "../utils/SocketHelper";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import "react-datetime/css/react-datetime.css";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { revertOffset, getOffset, setOffset } from "../utils/MomentWithOffset";
import { parse } from "query-string";
import { motion } from "framer-motion";
import moment from "moment";

const SPACES_BASE_URL = "https://telemetry.ams3.digitaloceanspaces.com";

type DownloadButtomState = {
  downloadButtomState: "request" | "waiting" | "building" | "download";
};

const DownloadButton = styled.a<DownloadButtomState>`
  width: 100%;
  border-color: black;
  background-color: ${(props: DownloadButtomState) =>
    props.downloadButtomState === "download" ? "black" : "white"};
  color: ${(props: DownloadButtomState) =>
    props.downloadButtomState === "download" ? "white" : "black"};

  &:hover {
    background-color: ${(props: DownloadButtomState) =>
      props.downloadButtomState === "building" && "white"};
    color: ${(props: DownloadButtomState) =>
      props.downloadButtomState === "building" && "black"};
    cursor: ${(props: DownloadButtomState) =>
      props.downloadButtomState === "building" && "auto"};
  }
`;

const Header = styled.div`
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  padding: 20px;

  background-color: white;

  z-index: 10;

  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
`;

const HeaderGrid = styled.div`
  width: 600px;
  height: 100%;
  display: grid;

  grid-template-areas:
    "type       type"
    "type       type"
    "type       type"
    "type       type"
    "dateStart  dateEnd"
    "timezone      check"
    "button     button";
  //"timezone      check";

  grid-template-columns: 1fr 1fr;
  grid-template-rows:
    auto
    auto
    auto
    auto
    auto
    auto
    40px;
  //auto;

  grid-column-gap: 20px;
`;

const TypeArea = styled.div`
  grid-area: type;
`;

const DateStartArea = styled.div`
  grid-area: dateStart;
`;

const DateEndArea = styled.div`
  grid-area: dateEnd;
`;

const ButtonArea = styled.div`
  grid-area: button;
`;

const TimezoneArea = styled.div`
  margin-top: 15px;
  grid-area: timezone;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const CheckArea = styled.div`
  margin-top: 15px;
  grid-area: check;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
`;

const SelectedSpanLabel = styled.span`
  position: absolute;
  top: -14px;
  margin-left: calc(50% - 50px);
  text-align: center;
  color: white;
  background-color: black;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
`;

//const singleSelectedVisualizations = [1, 2, 3, 4, 6];
const singleSelectedVisualizationsHeart = [1, 2];
const singleSelectedVisualizationsTemp = [1];

//it's an array, but explicit coded as a map

const liveVisualizationToRouteMap = (
  dataType: DataType,
  visualization: VisuType
) => {
  if (dataType === "temperature") {
    switch (visualization) {
      case 1:
        return "single_live_vis";
      case 2:
        return "live_temperature_table";
      case 3:
        return "three_coldest";
      case 4:
        return "team_live_vis";
    }
  } else if (dataType === "heart") {
    switch (visualization) {
      case 1:
        return "single_live_vis";
      case 2:
        return "single_live_vis";
      case 3:
        return "live_table";
      case 4:
        return "team_live_vis";
    }
  }
};

interface UserData {
  userId: string;
  username: string;
  selected?: boolean;
  connected?: boolean;
  google_id?: string;
}

type VisType = 1 | 2 | 3 | 4 | 5 | 6 | 7;

type DataType = "heart" | "temperature";
type WatchType = "live" | "download";
type VisuType = 1 | 2 | 3 | 4 | 5 | 6;
type TeamType = "BLACK" | "BLUE" | "GREEN" | "RED" | "WHITE" | "YELLOW";

function useForceUpdate() {
  let [_, setCounter] = useState(0);

  function forceUpdate() {
    setCounter((c) => c + 1);
  }

  return { forceUpdate };
}

function sortUserDataByName(a: UserData, b: UserData) {
  if (a.username > b.username) {
    return 1;
  }
  if (a.username < b.username) {
    return -1;
  }
  // a must be equal to b
  return 0;
}

const liveVisuOptionsTemp = [
  "9:16 Full-screen (portrait)",
  "Table",
  "3 Coldest",
  "5-Way Team Table",
];
const liveVisuOptionsHeart = [
  "16:9 Full-screen (landscape)",
  "9:16 Full-screen (portrait)",
  "Table",
  "5-Way Team Table",
];
const downloadVisuOptionsTemp = [
  "Value only",
  "16:9 Lower-third (landscape)",
  "9:16 Lower-third (portrait)",
  "9:16 Full-screen (portrait)",
  "5-Way Team Table",
  "3 Coldest",
];

const downloadVisuOptionsHeart = [
  "Value only",
  "16:9 Lower-third (landscape)",
  "9:16 Lower-third (portrait)",
  "16:9 Full-screen (landscape)",
  "9:16 Full-screen (portrait)",
  "5-Way Team Table",
];

const downloadGFXShortnamesHeart = [
  "heart-16x9-value-only",
  "heart-16x9-lower-third",
  "heart-9x16-lower-third",
  "heart-16x9-full-screen",
  "heart-9x16-full-screen",
  "heart-team-table",
];

const downloadGFXShortnamesTemp = [
  "temp-16x9-value-only",
  "temp-16x9-lower-third",
  "temp-9x16-lower-third",
  "temp-9x16-full-screen",
  // "temp-top3-coldest",
  "temp-team-table",
];

const checkVisualizationSingleSelected = (
  dataType: DataType,
  watchType: WatchType,
  visualization: number
): boolean => {
  if (watchType === "download") return true;
  switch (dataType) {
    case "heart":
      return singleSelectedVisualizationsHeart.includes(visualization);
    case "temperature":
      return singleSelectedVisualizationsTemp.includes(visualization);
  }
};

const teams = ["BLACK", "BLUE", "GREEN", "RED", "WHITE", "YELLOW"];

const LoadingBar = (props: any) => (
  <motion.div
    initial={{ width: 0 }}
    animate={{ width: `${props.progress}%` }}
    onAnimationComplete={() => {
      props.setDownloadButtomState("download");
      props.setProgress(0);
    }}
    transition={{ ease: "linear", duration: 1 }}
    style={{
      height: "30%",
      backgroundColor: "black",
    }}
  ></motion.div>
);

const BusyBar = (props: any) => (
  <motion.div
    initial={{ width: 0 }}
    animate={{ width: `100%` }}
    onAnimationComplete={() => {
      props.setDownloadButtomState("download");
      props.setProgress(0);
    }}
    transition={{ ease: "linear", duration: 3, loop: Infinity }}
    style={{
      height: "30%",
      backgroundColor: "black",
    }}
  ></motion.div>
);

const showTeams = (
  watchType: WatchType,
  dataType: DataType,
  visualization: VisuType
) => {
  let isTeamVis = false;

  if (watchType === "live") {
    if (visualization === 4) {
      isTeamVis = true;
    }
  } else {
    if (dataType === "heart") {
      if (visualization === 6) isTeamVis = true;
    } else {
      if (visualization === 5) isTeamVis = true;
    }
  }

  return isTeamVis;
};

const threeColdestSelected = (
  watchType: WatchType,
  dataType: DataType,
  visualization: VisuType
) => {
  if (
    (watchType === "live" &&
      dataType === "temperature" &&
      visualization === 3) ||
    (watchType === "download" &&
      dataType === "temperature" &&
      visualization === 6)
  ) {
    return true;
  }
  return false;
};

export default function UserListScreen() {
  let usersFirstRender = useRef(true);
  let alreadyInitializedUsers = useRef(false);
  let { forceUpdate } = useForceUpdate();

  let [users, setUsers] = useState<UserData[]>([]);
  let [connectedUsers, setConnectedUsers] = useState<UserData[]>([]);
  let [dataType, setDataType] = useState<DataType>(
    (localStorage.getItem("selectedDataType") || "heart") as DataType
  );
  let [watchType, setWatchType] = useState<WatchType>(
    (localStorage.getItem("selectedWatchType") || "live") as WatchType
  );
  let [visualization, setVisualization] = useState<VisuType>(
    parseInt(localStorage.getItem("selectedVisualization") || "1") as VisuType
  );
  let [team, setTeam] = useState<TeamType>(
    (localStorage.getItem("selectedTeam") || "BLACK") as TeamType
  );
  // let [showTeams, setShowTeams] = useState<string>(
  //   localStorage.getItem("showTeams") || "false"
  // );
  let [downloadButtomState, setDownloadButtomState] = useState<
    "request" | "waiting" | "building" | "download"
  >("request");
  let [progress, setProgress] = useState(0.01);

  let [downloadURL, setDownloadURL] = useState("");

  let queryObject = parse(window.location.search) as {
    accessToken?: string;
  };

  let startDateFromStorage = localStorage.getItem("@telemetry/start");
  let endDateFromStorage = localStorage.getItem("@telemetry/end");

  console.log({ startDateFromStorage, endDateFromStorage });
  const [start, setStart] = useState(
    startDateFromStorage ? new Date(startDateFromStorage as string) : new Date()
  );
  const [end, setEnd] = useState(
    endDateFromStorage ? new Date(endDateFromStorage as string) : new Date()
  );

  let history = useHistory();

  console.log({ showTeams });

  useEffect(() => {
    let { accessToken } = queryObject;
    socket.emit("getAllUsers", { accessToken });
    socket.emit("getConnected", { accessToken });
    socket.emit("join", queryObject.accessToken);
    socketGFX.emit("join", queryObject.accessToken);

    socket.on("getConnectedResponse", (connectedUsers: UserData[]) => {
      console.log(connectedUsers);

      setConnectedUsers(
        connectedUsers.map((user) => ({ ...user, connected: true }))
      );
    });

    socket.on("getAllUsersResponse", (users: UserData[]) => {
      console.log("USERS ", users);
      let selectedUsersText: string | null =
        localStorage.getItem("selectedUsers");
      console.log({ selectedUsersText });
      let selectedUsers: UserData[] = [];
      if (selectedUsersText) selectedUsers = JSON.parse(selectedUsersText);
      alreadyInitializedUsers.current = true;
      setUsers(
        users
          .map((user: any) => ({
            userId: user.google_id,
            username: user.name,
          }))
          .map((connectedUser) => {
            if (
              selectedUsers.find((user) => user.userId == connectedUser.userId)
            ) {
              return {
                ...connectedUser,
                selected: true,
              };
            }

            return connectedUser;
          })
      );
    });

    socketGFX.on(
      "downloadClipResponse",
      (response: {
        status:
          | "success"
          | "queue"
          | "processing"
          | "nodata"
          | "renderError"
          | "uploadError";
        progress?: number;
        data?: string;
        error?: any;
      }) => {
        console.log("respondeu");
        response.progress && setProgress(response.progress);
        console.log(response.status);
        if (response.status === "processing") {
          setDownloadButtomState("building");
        }
        if (response.status === "success" && response.data) {
          setDownloadURL(response.data);
        }
        if (response.status === "nodata") {
          toast.error(
            "There are no readings for this person in this period of time!",
            {
              autoClose: 6000,
            }
          );
          setDownloadButtomState("request");
        }
        if (response.status === "renderError") {
          toast.error("Error rendering video", {
            autoClose: 6000,
          });
          setDownloadButtomState("request");
        }
        if (response.status === "uploadError") {
          toast.error(`Error uploading video`, {
            autoClose: 6000,
          });
          setDownloadButtomState("request");
        }
      }
    );

    return () => {
      socket.off("getConnectedResponse");
      socketGFX.off("downloadClipResponse");
    };
    //socket.on;
  }, []);

  useEffect(() => {
    if (usersFirstRender.current) {
      usersFirstRender.current = false;
      return;
    }

    if (!alreadyInitializedUsers) return;

    console.log(JSON.stringify(users.filter((us) => us.selected)));
    localStorage.setItem(
      "selectedUsers",
      JSON.stringify(users.filter((us) => us.selected))
    );
  }, [users]);

  useEffect(() => {
    localStorage.setItem("selectedVisualization", visualization.toString());

    //if visualization is 3 coldest or 5 way team, de-select all users
    if (
      showTeams(watchType, dataType, visualization) ||
      threeColdestSelected(watchType, dataType, visualization)
    ) {
      setUsers(
        users.map((user) => {
          return { ...user, selected: false };
        })
      );
    }
  }, [visualization]);

  useEffect(() => {
    localStorage.setItem("selectedDataType", dataType);
  }, [dataType]);

  useEffect(() => {
    localStorage.setItem("selectedWatchType", watchType);
    // setVisualization(1);
    // setShowTeams("false");
  }, [watchType]);

  useEffect(() => {
    localStorage.setItem("selectedTeam", team);
  }, [team]);

  // useEffect(() => {
  //   localStorage.setItem("showTeams", showTeams);
  // }, [showTeams]);

  let usersWithConnectedSorted = users.map((user) => {
    if (connectedUsers.find((cUser) => cUser.userId == user.userId)) {
      return { ...user, connected: true };
    } else return user;
  });

  //This is not the same as `connectedUsers` because it contains more user Data, like if it's toggled.
  let connectedUsersArray: UserData[] = [];
  let usersArray: UserData[] = [];

  usersWithConnectedSorted.forEach((u) => {
    if (u.connected) {
      connectedUsersArray.push(u);
    } else {
      usersArray.push(u);
    }
  });

  connectedUsersArray = connectedUsersArray.sort(sortUserDataByName);
  usersArray = usersArray.sort(sortUserDataByName);

  usersWithConnectedSorted = [...connectedUsersArray, ...usersArray];

  //Put connected users on top
  // usersWithConnectedSorted = usersWithConnectedSorted.sort((a, b) => {
  //   let aValue = a.connected ? 1 : 0;
  //   let bValue = b.connected ? 1 : 0;

  //   return bValue - aValue;
  // });

  useEffect(() => {
    localStorage.setItem("@telemetry/start", start.toString());
  }, [start]);

  useEffect(() => {
    localStorage.setItem("@telemetry/end", end.toString());
  }, [end]);

  console.log({ usersWithConnectedSorted, connectedUsers });

  return (
    <div css={styledRoot}>
      <Header>
        <div
          css={css`
            position: absolute;
            left: 92vw;
            top: 2vh;
          `}
        >
          <a
            css={css`
              &:hover {
                cursor: pointer;
              }
            `}
            style={{ fontSize: 15, color: "black" }}
            onClick={() => {
              localStorage.setItem("accessToken", "null");
              history.replace("/");
            }}
          >
            {" "}
            Log out
          </a>
        </div>
        <HeaderGrid>
          <TypeArea>
            <label>Data Type</label>
            <select
              value={dataType}
              onChange={(evt) => {
                let newVisualization = evt.target.value;
                setDataType(newVisualization as DataType);
              }}
              disabled={downloadButtomState === "building" ? true : false}
            >
              <option value="heart">Heart</option>
              <option value="temperature">Temperature</option>
            </select>
            <label>Live or Download?</label>
            <select
              value={watchType}
              onChange={(evt) => {
                let newVisualization = evt.target.value;
                setWatchType(newVisualization as WatchType);
              }}
              disabled={downloadButtomState === "building" ? true : false}
            >
              <option value="live">Live</option>
              <option value="download">Download</option>
            </select>
            <label>Choose Visualization</label>
            <select
              value={visualization}
              onChange={(evt) => {
                let newVisualization = parseInt(evt.target.value);
                // let isTeamVis = false;

                // if (watchType === "live") {
                //   if (newVisualization === 4) {
                //     isTeamVis = true;
                //   }
                // } else {
                //   if (dataType === "heart") {
                //     if (newVisualization === 6) isTeamVis = true;
                //   } else {
                //     if (newVisualization === 5) isTeamVis = true;
                //   }
                // }

                // console.log({ isTeamVis });

                // setShowTeams(isTeamVis ? "true" : "false");

                let lastVisualizationWasNotSingleSelected =
                  !checkVisualizationSingleSelected(
                    dataType,
                    watchType,
                    visualization
                  );
                let isVisualizationSingleSelected =
                  checkVisualizationSingleSelected(
                    dataType,
                    watchType,
                    newVisualization
                  );

                //If visualization single mode selected clears all user selected
                setUsers((users) =>
                  users.map((user) =>
                    lastVisualizationWasNotSingleSelected &&
                    isVisualizationSingleSelected &&
                    users.filter((us) => us.selected).length > 1 //if visualization was multi and now is single mode, and there's more than one user selected
                      ? { ...user, selected: false }
                      : user
                  )
                );

                setVisualization(newVisualization as VisuType);
              }}
              disabled={downloadButtomState === "building" ? true : false}
            >
              {watchType === "live"
                ? (dataType === "heart"
                    ? liveVisuOptionsHeart
                    : liveVisuOptionsTemp
                  ).map((option: string, index: number) => {
                    return (
                      <option value={(index + 1).toString()}>{option}</option>
                    );
                  })
                : (dataType === "heart"
                    ? downloadVisuOptionsHeart
                    : downloadVisuOptionsTemp
                  ).map((option: string, index: number) => {
                    return (
                      <option value={(index + 1).toString()}>{option}</option>
                    );
                  })}
            </select>
            {showTeams(watchType, dataType, visualization) && (
              <React.Fragment>
                <label>Team</label>
                <select
                  value={team}
                  onChange={(evt) => {
                    let newTeam = evt.target.value;
                    setTeam(newTeam as TeamType);
                  }}
                  disabled={downloadButtomState === "building" ? true : false}
                >
                  {teams.map((option: string, index: number) => {
                    return <option value={option}>{option}</option>;
                  })}
                </select>
              </React.Fragment>
            )}
          </TypeArea>

          {
            /*[1, 2].includes(visualization)*/ watchType === "download" && (
              <React.Fragment>
                <DateStartArea>
                  <label>Date Start</label>
                  <DatePicker
                    selected={start}
                    onChange={(date: Date) => {
                      // console.log("START DATE = ", start.toISOString()); // O problema está no toISOString()
                      console.log("START DATE = ", date);
                      console.log("DATE OFFSET = ", getOffset());
                      setStart(date);
                    }}
                    showTimeSelect
                    timeFormat="HH:mm:ss"
                    timeIntervals={1}
                    // timeCaption="time"
                    dateFormat="MMMM d, yyyy HH:mm:ss"
                    // timeInputLabel="Time:"
                    // showTimeInput
                    disabled={downloadButtomState === "building" ? true : false}
                  />
                </DateStartArea>

                <DateEndArea>
                  <label>Date End</label>

                  <DatePicker
                    selected={end}
                    onChange={(date: Date) => {
                      console.log("END DATE = ", date);
                      console.log("DATE OFFSET = ", getOffset());
                      setEnd(date);
                    }}
                    showTimeSelect
                    timeFormat="HH:mm:ss"
                    timeIntervals={1}
                    // timeCaption="time"
                    dateFormat="MMMM d, yyyy HH:mm:ss"
                    // timeInputLabel="Time:"
                    // showTimeInput
                    disabled={downloadButtomState === "building" ? true : false}
                  />
                </DateEndArea>
              </React.Fragment>
            )
          }
          {watchType === "download" && (
            <TimezoneArea>
              <label>Timezone</label>
              <select
                value={getOffset() - new Date().getTimezoneOffset() / 60}
                onChange={(evt) => {
                  let offset = parseInt(evt.target.value);
                  setOffset(offset);
                  forceUpdate();
                }}
                disabled={downloadButtomState === "building" ? true : false}
              >
                {Array(26)
                  .fill(0)
                  .map((_, index) => {
                    let timezone = index - 14;
                    return (
                      <option value={timezone} key={timezone}>
                        {/*{timezone + 1} key={timezone}> //para horario de verao*/}
                        {timezone >= 0 ? "+" : ""}
                        {timezone}
                      </option>
                    );
                  })}
              </select>
            </TimezoneArea>
          )}

          <ButtonArea>
            {watchType === "live" ? (
              <a
                className="button"
                css={css`
                  width: 100%;
                  background-color: black;
                  border-color: black;
                `}
                onClick={() => {
                  let selectedUsers = users.filter((user) => user.selected);
                  let userIds = selectedUsers.map((user) => user.userId);
                  let userNames = selectedUsers.map((user) => user.username);

                  if (
                    !showTeams(watchType, dataType, visualization) &&
                    !threeColdestSelected(watchType, dataType, visualization) &&
                    selectedUsers.length === 0
                  ) {
                    toast.error(
                      "Select at least one user for the visualization process!"
                    );
                    return;
                  }

                  let startWithoutOffset = revertOffset(start);
                  let endWithoutOffset = revertOffset(end);

                  // history.push(
                  //   `/${visualizationToRouteMap[visualization]}/${userIds}/${userNames}?accessToken=${queryObject.accessToken}&showGreenScreen=${showGreenScreen}&showOnScreenDate=${showOnScreenDate}&start=${startWithoutOffset}&end=${endWithoutOffset}`
                  // );

                  let route = liveVisualizationToRouteMap(
                    dataType,
                    visualization
                  );

                  let orientation = "";
                  if (dataType === "temperature" && visualization === 1) {
                    orientation = "9x16";
                  } else {
                    if (dataType === "heart") {
                      switch (visualization) {
                        case 1:
                          orientation = "16x9";
                          break;
                        case 2:
                          orientation = "9x16";
                          break;
                      }
                    }
                  }
                  if (route === "three_coldest") {
                    history.push(
                      `/${route}?accessToken=${queryObject.accessToken}`
                    );
                  } else if (route === "team_live_vis") {
                    history.push(
                      `/${route}?accessToken=${queryObject.accessToken}&team=${team}&type=${dataType}`
                    );
                  } else if (route !== "") {
                    // history.push(
                    //   `/${route}/${userIds}/${userNames}?accessToken=${queryObject.accessToken}&showGreenScreen=${showGreenScreen}&showOnScreenDate=${showOnScreenDate}&start=${startWithoutOffset}&end=${endWithoutOffset}`
                    // );
                    history.push(
                      `/${route}/${userIds}/${userNames}?accessToken=${queryObject.accessToken}&start=${startWithoutOffset}&end=${endWithoutOffset}&type=${dataType}&orientation=${orientation}`
                    );
                  }
                }}
              >
                Visualize
              </a>
            ) : (
              <DownloadButton
                className="button"
                //css={dynamicButtonStyle}
                style={{
                  marginLeft: 0,
                  paddingLeft: 0,
                  marginRight: 0,
                  paddingRight: 0,
                }}
                onClick={
                  downloadButtomState === "request" //&&
                    ? // dataType === "heart" &&
                      // visualization === 2
                      () => {
                        setDownloadButtomState("waiting");
                        console.log("REQUEST");
                        //setDownloadButtomState("building");
                        let selectedUsers = users.filter(
                          (user) => user.selected
                        );
                        let userNames = selectedUsers.map(
                          (user) => user.username
                        );
                        let userIds = selectedUsers.map((user) => user.userId);

                        let startWithOffset = new Date(start);
                        startWithOffset.setHours(
                          start.getHours() - getOffset()
                        );
                        console.log(
                          "FINAL start = ",
                          startWithOffset.toString()
                        );

                        let endWithOffset = new Date(end);
                        endWithOffset.setHours(end.getHours() - getOffset());
                        console.log("FINAL end = ", endWithOffset.toString());

                        if (
                          !showTeams(watchType, dataType, visualization) &&
                          !threeColdestSelected(
                            watchType,
                            dataType,
                            visualization
                          ) &&
                          selectedUsers.length === 0
                        ) {
                          toast.error(
                            "Select at least one user for the visualization process!"
                          );
                          setDownloadButtomState("request");

                          return;
                        } else {
                          //if selected vis is 5 way team table
                          if (
                            (dataType === "heart" && visualization === 6) ||
                            (dataType === "temperature" && visualization === 5)
                          ) {
                            socketGFX.emit("downloadTeamTableClip", {
                              start: startWithOffset,
                              end: endWithOffset,
                              startHHMMSS: moment(start).format("HHmmss"),
                              endHHMMSS: moment(end).format("HHmmss"),
                              accessToken: queryObject.accessToken,
                              GFXshortname:
                                dataType === "heart"
                                  ? downloadGFXShortnamesHeart[
                                      visualization - 1
                                    ]
                                  : downloadGFXShortnamesTemp[
                                      visualization - 1
                                    ],
                              type: dataType,
                              team: team,
                            });
                          } else if (
                            //if three coldest
                            dataType === "temperature" &&
                            visualization === 6
                          ) {
                            socketGFX.emit("downloadThreeColdestClip", {
                              start: startWithOffset,
                              end: endWithOffset,
                              accessToken: queryObject.accessToken,
                            });
                          } else {
                            socketGFX.emit("downloadClip", {
                              start: startWithOffset, //start,
                              end: endWithOffset, //end,
                              startHHMMSS: moment(start).format("HHmmss"),
                              endHHMMSS: moment(end).format("HHmmss"),
                              name: userNames[0],
                              user_id: userIds[0],
                              accessToken: queryObject.accessToken,
                              GFXshortname:
                                dataType === "heart"
                                  ? downloadGFXShortnamesHeart[
                                      visualization - 1
                                    ]
                                  : downloadGFXShortnamesTemp[
                                      visualization - 1
                                    ],
                              type: dataType,
                            });
                          }
                        }
                      }
                    : downloadButtomState === "download"
                    ? () => {
                        window.open(`${SPACES_BASE_URL}/${downloadURL}`);
                        setDownloadButtomState("request");
                      }
                    : () => {}
                }
                downloadButtomState={downloadButtomState}
              >
                {downloadButtomState === "request" ? (
                  "Request Clip"
                ) : downloadButtomState === "download" ? (
                  "Download"
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "flex-start",
                      marginLeft: 0,
                      marginRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div
                      style={{
                        height: "70%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <text>
                        {downloadButtomState === "building"
                          ? "Building Clip"
                          : "Your clip is in queue to be processed"}
                      </text>
                    </div>
                    {downloadButtomState === "building" ? (
                      <LoadingBar
                        progress={progress * 100}
                        setDownloadButtomState={setDownloadButtomState}
                        setProgress={setProgress}
                      />
                    ) : (
                      <BusyBar />
                    )}
                  </div>
                )}
              </DownloadButton>
            )}
          </ButtonArea>
        </HeaderGrid>
      </Header>

      <div
        css={css`
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 100vw;
          height: calc(100vh - 120px);
          overflow-y: scroll;
        `}
      >
        {usersWithConnectedSorted.map((user) => (
          <div
            key={user.userId}
            css={[
              userCardStyle,
              user.selected &&
                css`
                  border-style: solid;
                  border-color: black;
                  border-width: 3px;
                `,
            ]}
            onClick={() => {
              if (
                showTeams(watchType, dataType, visualization) ||
                threeColdestSelected(watchType, dataType, visualization)
              ) {
                console.log("do nothing");
              } else {
                let selectedUser = user;
                // let isVisualizationSingleSelected =
                //   watchType === "download" ||
                //   singleSelectedVisualizationsHeart.includes(visualization);
                let isVisualizationSingleSelected =
                  checkVisualizationSingleSelected(
                    dataType,
                    watchType,
                    visualization
                  );

                setUsers((users) =>
                  users.map((user) =>
                    user.userId == selectedUser.userId //toggle the user selected
                      ? { ...user, selected: !user.selected }
                      : isVisualizationSingleSelected //if visualization is single mode, untoggle the others
                      ? { ...user, selected: false }
                      : user
                  )
                );
              }
            }}
          >
            <span
              css={css`
                font-size: 20px;
                font-weight: bold;
                display: flex;
                flex-direction: row;
                align-items: center;
              `}
            >
              {user.username}{" "}
              {user.connected && (
                <div
                  css={css`
                    margin-left: 15px;
                    background-color: green;
                    border-radius: 4px;
                    padding-left: 6px;
                    padding-right: 6px;
                    color: white;
                    font-size: 12px;
                  `}
                >
                  connected
                </div>
              )}
            </span>
            <span
              css={css`
                font-size: 14px;
                opacity: 0.5;
              `}
            >
              #{user.userId}
            </span>

            {/* <LottieHeart
              speed={1}
              style={{
                width: 100,
                height: 100
              }}
            /> */}

            {user.selected && (
              <SelectedSpanLabel css={css}>{" selected "}</SelectedSpanLabel>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export const NoSelect = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
`;

const styledRoot = css`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  background-color: #e2e1e0;
`;

const userCardStyle = css`
  ${NoSelect}
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

  position: relative;

  height: 80px;
  width: 600px;
  background-color: white;
  margin-top: 20px;
  box-sizing: content-box;
  display: flex;

  justify-content: space-between;
  flex-direction: column;

  padding: 15px;

  &:active {
    opacity: 0.6;
  }
`;
